<template>
  <div>
    <Header :title="'提前转出'" :back="true"/>
    <div class="top-box flex-box">
      <div>
        <div>预期年化收益率</div>
        <div class="theme-color">{{rate|formatFloat}}%</div>
      </div>
      <div>
        <div>剩余锁定天数</div>
        <div class="theme-color flex-box dd">
          <div>{{day}}</div>
          <div style="font-size:1rem;line-height:2.5rem"> 天(自然日)</div>
        </div>
      </div>
    </div>
    <div class="box-items">
      <div >
        <div class="theme-color" style="margin-bottom:10px">转出金额</div>
        <div class="theme-color" style="font-size:2rem">${{money|formatFloat}}</div>
      </div>
      <div class="tips-min">必须转出全部资金，不可部分转出</div>
    </div>
    <div class="box-items">
      
      <div class="automatic-tips">
        <div class="automatic-box flex-box">
          <div>手续费(1.5%)</div>
          <div class="theme-color">
            ${{money*sellrate|formatFloat}}
          </div>
        </div>
        <div class="automatic-box flex-box">
          <div>到账金额</div>
          <div class="theme-color">
            ${{money -money*sellrate |formatFloat}}
          </div>
        </div>
      </div>
    </div>
    <div class="tips-min2"><i class="el-icon-warning"></i> 锁定期内可以选择提前转出，手续费为转出金额的1.5%。</div>
    <div class="tips-min2"><i class="el-icon-warning"></i> 提前转出申请当日受理，申请当日起不再计息。</div>
    <div class="tips-min2"><i class="el-icon-warning"></i> 本金及收益将在下一个交易日转出至现金余额，可在“持有”页面查看。</div>
    <div class="finacing-footer" >
      <button class="add-btn theme-bgcolor" :disabled="status=='calling'?true:false" @click="drawer=true">确认转出</button>
    </div>
    <el-dialog
      :visible.sync="drawer"
      width="85%"
      :show-close="false"
      custom-class="pwd-box"
      >
      <div class="draw-box">
        <div class="close-icon" @click="drawer=false"><i class="el-icon-circle-close theme-color"></i></div>
        <div class="draw-title">请输入交易密码</div>
        <div>
          <Password ref="passwordInputRef"
            mask
            :pwdd.sync="password"
            @complete="onPasswordInputComplete"/>
        </div>
        <div class="draw-pwd theme-color" @click="forgetPwd">忘记密码？</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from '@/components/Header'
import Password from '@/components/Password/Index'
export default {
  components: {
    Header,
    Password
  },
  data(){
    return{
      drawer:false,
      password:'',
      automatic:false,
      day:'',
      money:'',
      startDate:'',
      endDate:'',
      total:'',
      income:'',
      rate:'',
      sellrate:null,
      status:''
    }
  },
  created(){
    this.getData()

  },
  methods:{
    getData(){
      let params={
        id:this.$route.query.key
      }
      this.$store
        .dispatch('stages/buyDetail',{params:params})
        .then((res) => {
          this.day = res.investment.remainDays
          this.rate =  res.investment.rate
          this.money= res.investment.worth
          this.sellrate = res.investment.sellrate
          this.status =res.investment.status
        })
    },
   
    forgetPwd(){
      this.$router.push({name:'forgetpwd'})
    },
    onPasswordInputComplete(pwd){
      let params={
        code:this.$route.query.key,
        status:'calling',
        tradepassword:pwd
      }
      this.$store
        .dispatch('stages/callback',params)
        .then((res) => {
          if(res.code){
            this.$router.push({path:'paysuccess',query:{key:res.code}})
          }
        }).finally(()=>{
          this.password=null
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.automatic-box{
  justify-content: space-between;
  align-self:center;
  margin-bottom:4%
}
.box-items{
  margin-top:2.5%
}
.automatic-tips{
  margin:10px 5px;
  // border-top:1px solid #eee;
  padding-top:10px;
  color: #464646;
  line-height: 1.5;
}
.tips-min{
  font-size: .9rem;
  color: #888;
}
.tips-min2{
  font-size: .9rem;
   color: #888;
   margin:3%
}
.top-box{
  background: #fff;
  padding: 5%;
  justify-content: space-around;
  text-align: center;
  >div{
     width: 50%;
     font-size: 2rem;
      >:first-child{
        font-size: .9rem;
        color: #464646;
      }
  }
  >:first-child{
    border-right:1px solid #eee
  }
}
.finacing-footer{
  position: fixed;
  bottom:0px;
  width: 95%;
  padding: 2.5%;
  text-align: center;
  .add-btn{
    border-radius:8px;
    width: 95%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
    border:none

  }
  .add-btn:disabled{
    background-color: #cecece !important;
    border:none !important;
    color: #666;

  }
}
.dd{
  align-items: flex-end;
  justify-content: center;
}
.draw-box{
  padding: 5%;
  :not(:first-child) div{
    margin-bottom:10px;
    margin-left:5%;
    margin-right:5%;
  }
  .close-icon{
    text-align: right;
    font-size: 1.5rem;
    margin-bottom:0px !important
  }
  .draw-title{
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #989898;
    text-align: center;
    padding-top:5px;
    padding-bottom:15px
  }
  .draw-pwd{
    margin-top:1rem;
    text-align: right;
    margin-right:5%;
    font-size: .8rem;
  }
}
</style>